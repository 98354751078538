import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import slug from 'slug';
import _ from 'lodash';
// import logo from '../images/logo/logo.png';

const Footer = () => (
  <StaticQuery
    query={graphql`
    query siteFooterDetails {
      allServicesJson{
        edges{
          node{
             name
          }
        }
      }
      dataJson{
        logo{
          childImageSharp{
            sizes( maxWidth: 154 ) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        phoneNumber
        email
        address{
          line1
          line2
        }
      }
    }
  `}
    render={(data) => {
      const {
        allServicesJson: { edges: services },
        dataJson: {
          logo,
          phoneNumber,
          email,
          address,
        },
      } = data;
      return (
        <footer className="footer-area">
          <div className="container">
            <div className="row">
              {/* Start single footer widget */}
              <div className="col-lg-4 col-md-3 col-sm-12 col-xs-12">
                <div className="single-footer-widget pd-bottom">
                  <div className="footer-logo logo">
                    <Link to="/">
                      <Img
                        src={logo.childImageSharp.sizes.src}
                        sizes={logo.childImageSharp.sizes}
                        alt="Hamilton Wireless"
                      />
                    </Link>
                  </div>
                  <div className="repairplus-info">
                    <p className="top">Hamilton Wireless brings many years of Digital Repairs experience right to your Device. Our Texhnicians are equipped to help you that work best.</p>
                    <p>Our commitment to bring professionalism, good service &amp; trust to the Phone repair service &amp; maintenance business.</p>
                  </div>
                </div>
              </div>
              {/* End single footer widget */}
              <div className="col-lg-8 col-md-9 col-sm-12 col-xs-12">
                <div className="footer-widget">
                  <div className="row">
                    {/* Start single footer widget */}
                    <div className="col-lg-4 col-md-4 col-sm-12">
                      <div className="single-footer-widget">
                        <div className="title">
                          <h3>Our Services</h3>
                        </div>
                        <ul className="services-list">
                          {
                            services.map(service => <li key={service.node.name}><Link to={`/services/${slug(_.lowerCase(service.node.name))}`}>{service.node.name}</Link></li>)
                          }
                        </ul>
                      </div>
                    </div>
                    {/* Start single footer widget */}
                    {/* Start single footer widget */}
                    <div className="col-lg-4 col-md-4 col-sm-12" />
                    {/* End single footer widget */}
                    {/* Start single footer widget */}
                    <div className="col-lg-4 col-md-4 col-sm-12">
                      <div className="single-footer-widget contact-info-widget">
                        <div className="title">
                          <h3>Contact Info</h3>
                        </div>
                        <ul className="footer-contact-info">
                          <li>
                            <div className="icon-holder">
                              <span className="flaticon-home-page" />
                            </div>
                            <div className="text-holder">
                              <h5>
                                <span>{address.line1}</span>
                                <br />
                                {address.line2}
                              </h5>
                            </div>
                          </li>
                          <li>
                            <div className="icon-holder">
                              <span className="flaticon-call-answer" />
                            </div>
                            <div className="text-holder">
                              <h5>
                                <span>Call Us</span>
                                <br />
                                {phoneNumber}
                              </h5>
                            </div>
                          </li>
                          <li>
                            <div className="icon-holder">
                              <span className="flaticon-envelope" />
                            </div>
                            <div className="text-holder">
                              <h5>
                                <span>Mail Us</span>
                                <br />
                                {email}
                              </h5>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* End single footer widget */}
                  </div>
                </div>
              </div>
            </div>
            {/* Start footer botton */}
            <div className="row">
              <div className="col-md-12">
                <div className="footer-bottom">
                  <div className="copyright-text pull-left">
                    <p>
                      Copyrights © 2017 All Rights Reserved by
                      <a href="#"> Hamilton Wireless</a>
                    </p>
                  </div>
                  <div className="payment-method pull-right">
                    <ul>
                      <li><a href="#"><i className="fa fa-cc-visa" aria-hidden="true" /></a></li>
                      <li><a href="#"><i className="fa fa-cc-paypal" aria-hidden="true" /></a></li>
                      <li><a href="#"><i className="fa fa-cc-mastercard" aria-hidden="true" /></a></li>
                      <li><a href="#"><i className="fa fa-cc-amex" aria-hidden="true" /></a></li>
                      <li><a href="#"><i className="fa fa-cc-discover" aria-hidden="true" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* End footer botton */}
          </div>
        </footer>
      );
    }}
  />
);
export default Footer;
