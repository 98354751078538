import React, { Component } from 'react';
import $ from 'jquery';
import loadScript from 'simple-load-script';
// CSS files
import '../static/css/imp.css';
import '../static/css/bootstrap.min.css';
import '../static/css/font-awesome.min.css';
import '../static/fonts/flaticon/flaticon.css';
import '../static/css/style.css';
import '../static/css/responsive.css';

class ImportScripts extends Component {
  componentDidMount() {
    window.$ = $;
    window.jQuery = $;
    loadScript('/js/bootstrap.min.js');
    loadScript('/js/jquery.countTo.js');
    loadScript('/js/owl.carousel.min.js');
    loadScript('/js/jquery.mixitup.min.js');
    loadScript('/js/jquery.easing.min.js');
    loadScript('/js/main.js');
  }

  render() {
    return <script />;
  }
}
export default ImportScripts;
