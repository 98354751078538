import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import slug from 'slug';
import _ from 'lodash';

const Menu = () => (
  <StaticQuery
    query={graphql`
      query siteMenuDetails {
        allServicesJson{
          edges{
            node{
              id
               name
              description
              image{
                childImageSharp{
                  id
                  sizes( maxWidth: 800 ) {
                    ...GatsbyImageSharpSizes
                  }
                }
              }
            }
          }
        }
        dataJson{
          phoneNumber
          email
          address{
            line1
            line2
          }
        }
      }
    `}
    render={(data) => {
      const {
        dataJson: { phoneNumber },
        allServicesJson: { edges: services },
      } = data;
      return (
        <section className="mainmenu-area stricky">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-sm-9 col-xs-4">
                {/* Start mainmenu */}
                <nav className="main-menu">
                  <div className="navbar-header">
                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                    </button>
                  </div>
                  <div className="navbar-collapse collapse clearfix">
                    <ul className="navigation clearfix">
                      <li className="current"><Link to="/">Home</Link></li>
                      <li className="dropdown">
                        <Link to="/services">Services</Link>
                        <ul>
                          {services.map(service => <li key={service.node.id}><Link to={`/services/${slug(_.lowerCase(service.node.name))}`}>{service.node.name}</Link></li>)}
                        </ul>
                      </li>
                      <li><Link to="/services/ip-tv-setup">IPTV</Link></li>
                      <li><Link to="/buy-phone">New/Used Phone</Link></li>
                      <li><Link to="/plans">Plans</Link></li>
                      <li><Link to="/contact">Contact Us</Link></li>
                    </ul>
                  </div>
                </nav>
                {/* End mainmenu */}
              </div>
              <div className="col-md-4 col-sm-3 col-xs-8">
                <div className="customer-care pull-right">
                  <div className="icon-holder">
                    <span className="flaticon-cell-phone" />
                  </div>
                  <div className="title-holder">
                    <h5>Customer Care</h5>
                    <h4>{phoneNumber}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }}
  />
);

export default Menu;
