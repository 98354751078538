import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';

const topBar = () => (
  <StaticQuery
    query={graphql`
    query siteTopBarDetail {
      site{
        siteMetadata{
          title
        }
      }
      dataJson{
        phoneNumber
        email
        address{
          line1
          line2
        }
        socialSiteLink{
          facebook
          instagram
          twitter
        }
      }
    }
  `}
    render={(data) => {
      const { dataJson: { socialSiteLink: { facebook, twitter, instagram } } } = data;
      const { site: { siteMetadata: { title } } } = data;
      return (
        <section className="top-bar-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-5 col-sm-12 col-xs-12">
                <div className="welcome">
                  <p>{title}</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-5 col-sm-12 col-xs-12">
                <div className="topinfo clearfix">
                  <div className="select-items clearfix">
                    <div className="single-select-item extras" />
                    <div className="single-select-item" />
                  </div>
                  {/* <div className="rate">
              <h6>Repair Rates</h6>
            </div> */}
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                <div className="top-social-links">
                  <ul>
                    <li><Link to={facebook}><i className="fa fa-facebook" aria-hidden="true" /></Link></li>
                    <li><Link to={twitter}><i className="fa fa-twitter" aria-hidden="true" /></Link></li>
                    <li><Link to={instagram}><i className="fa fa-instagram" aria-hidden="true" /></Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }}
  />
);
export default topBar;
