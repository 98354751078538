import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import ImportScripts from './importScripts';
// import Brand from './brand';
import Menu from './menu';
import Header from './header';
import Footer from './footer';
import TopBar from './topBar';
import './layout.css';

import {
  title,
  description,
  siteUrl,
  type,
  keyword,
} from '../../site-config';

import favicon16 from '../images/favicon/favicon-16.png';
import favicon32 from '../images/favicon/favicon-32.png';

class Layout extends PureComponent {
  handleScrollToTop = (event) => {
    const target = window.$(event.currentTarget).attr('data-target');
    window.$('html, body').animate({
      scrollTop: window.$(target).offset().top,
    }, 1000);
  }

  render() {
    const { children } = this.props;
    return (
      <StaticQuery
        query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
        render={data => (
          <React.Fragment>
            <Helmet
              title={title}
              meta={[
                { name: 'google-site-verification', content: 'aKnrSnjtpfVhS207f7444sl7e4SnefSvpwxtcd-on3o' },
                { name: 'description', content: description },
                { name: 'keyword', content: keyword },
                { property: 'og:url', content: siteUrl },
                { property: 'og:image', content: siteUrl + "/icons/icon-256x256.png" },
                { property: 'og:title', content: title },
                { property: 'og:type', content: type },
                { property: 'og:description', content: description },
              ]}
              link={[
                {
                  rel: 'icon', type: 'image/png', sizes: '16x16', href: favicon16,
                },
                {
                  rel: 'icon', type: 'image/png', sizes: '32x32', href: favicon32,
                },
                { rel: 'canonical', href: siteUrl },
              ]}
              >
            <html lang="en" />              
            </Helmet>
            <ImportScripts />
            <div id="content">
            {/* <div class="preloader"></div> */}
              <TopBar />
              <Header siteTitle={data.site.siteMetadata.title} />
              <Menu />
              <div
                style={{
                  margin: '0 auto',
                  maxWidth: '100%',
                  paddingTop: 0,
                }}
              >
                {children}
              </div>
              <button type="button" onClick={this.handleScrollToTop} className="scroll-to-top scroll-to-target" data-target="html">
                <span className="fa fa-angle-up" />
              </button>
              {/* <Brand /> */}
              <Footer />
            </div>
          </React.Fragment>
        )}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
